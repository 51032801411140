body :is(*) {
  @apply font-body;
}
@font-face {
  font-family: ClanOT-Medium;
  src: url('../../fonts/ClanOT/ClanOT-Medium.otf') format('opentype'); 
}
@font-face {
  font-family: ClanOT-MediumItalic;
  src: url('../../fonts/ClanOT/ClanOT-MediumItalic.otf') format('opentype'); 
}
@font-face {
  font-family: ClanOT-NarrBlack;
  src: url('../../fonts/ClanOT/ClanOT-NarrBlack.otf') format('opentype'); 
}
@font-face {
  font-family: ClanOT-NarrBlackItalic;
  src: url('../../fonts/ClanOT/ClanOT-NarrBlackItalic.otf') format('opentype'); 
}
@font-face {
  font-family: ClanOT-NarrBold;
  src: url('../../fonts/ClanOT/ClanOT-NarrBold.otf') format('opentype'); 
}
@font-face {
  font-family: ClanOT-NarrBoldItalic;
  src: url('../../fonts/ClanOT/ClanOT-NarrBoldItalic.otf') format('opentype'); 
}

@font-face {
  font-family: ClanOT-NarrBook;
  src: url('../../fonts/ClanOT/ClanOT-NarrBook.otf') format('opentype'); 
}
@font-face {
  font-family: ClanOT-NarrBookItalic;
  src: url('../../fonts/ClanOT/ClanOT-NarrBlackItalic.otf') format('opentype'); 
}
@font-face {
  font-family: ClanOT-NarrMedium;
  src: url('../../fonts/ClanOT/ClanOT-NarrMedium.otf') format('opentype'); 
}
@font-face {
  font-family: ClanOT-NarrMediumItalic;
  src: url('../../fonts/ClanOT/ClanOT-NarrMediumItalic.otf') format('opentype'); 
}
@font-face {
  font-family: ClanOT-NarrNews;
  src: url('../../fonts/ClanOT/ClanOT-NarrNews.otf') format('opentype'); 
}
@font-face {
  font-family: ClanOT-NarrNewsItalic;
  src: url('../../fonts/ClanOT/ClanOT-NarrNewsItalic.otf') format('opentype'); 
}
@font-face {
  font-family: ClanOT-NarrowThin;
  src: url('../../fonts/ClanOT/ClanOT-NarrowThin.otf') format('opentype'); 
}
@font-face {
  font-family: ClanOT-NarrowUltra;
  src: url('../../fonts/ClanOT/ClanOT-NarrowUltra.otf') format('opentype'); 
}

