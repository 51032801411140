@import url('typography.css');
input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6{
  font-family: "ClanOT-Medium";
}

strong{
  font-family: "ClanOT-NarrBold";
}

p,a,div,span,label{
  font-family: "ClanOT-NarrNews";
}

button, input, optgroup, select, textarea {
  font-family: "ClanOT-NarrNews"!important;
}

.cflx-text-thin{
  font-family: 'ClanOT-NarrowThin';
  font-weight: 450;
}

.text-bold-conv{
  font-family:  "ClanOT-NarrBold";
  font-weight: bold;
}
svg.thick-svg path {
  stroke-width: 2;
}
.coflex-page{
  @media (min-width: 1024px) {
    width: calc(100vw - 7rem);
  }
}

.entity-form-iputs input{
color: #212121;
}
.send-conf svg{
  margin:auto;
}
.list-category label span{
 color:#212121
}

.product-code p{
 font-weight: bold;
 font-size: 1.1rem;
 color:#1F6DFF;
}

 button.add-cart{
  background-color: #ffffff ;
  border: 2px solid #c2d7ff;
  color:#001a49;
  font-size: larger;

}
button.add-cart:hover{
  color:white;
}
.title-size-bo div span{
  font-size: 18px !important;
}
/* button.quantity-btn{
  background-color: #eff3fb;
  font-size: 2rem;
}
button.quantity-btn:hover{
  background-color: #c2d7ff;
}
form.quantity-btn{
  width: 5rem;
}
form.quantity-btn input{
  color:#001a49
} */

.links-pdp{
  min-width: 8.5rem;
  max-width: 8.5rem;
}

.links-pdp a{
  width: 100%;
}

.lg-search div.border{
  border-radius: 9999px;
    --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.04), 0 8px 10px -6px rgb(0 0 0 / 0.1);
    --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color), 0 8px 10px -6px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);

}

.lg-search div input{
  padding:12px 12px 12px 30px;
  background-color: #fafafa;
}

.search-size{
  width:3.65rem;
  height:3.65rem;
  margin-top: .6rem;
}

.range-price input{
  color:#212121;
}


.cfont-sm{
  font-size: 12px;
}


.footer-links {
  display:grid;
}
.footer-links  a{
  width: 100%;
}

@media(max-width:767px){
  .grid-mobile-view{
    grid-template-columns: repeat(2, minmax(0, 1fr)) !important;
  }
}
@media(max-width:1024px){
 .search-size{
  width: 3rem;
  height: 3rem;
 }
 .lg-search div.border{
  position: relative;
 }
 .cfont-sm div{
 text-align: center;
 }

 .search-size{
  margin-top:0;

 }
 .footer-contact{
  margin-left: .2rem;
 }
 
}

@media(min-width:1024px){
  .footer-bar-bottom{
    display:flex;
    justify-content: center;
   }
}

@media(min-width:1600px){
  /* .account-info{
  display: block;
  } */
  .search-bar-w{
    width: 550px;
}
}

/* @media(max-width:1400px){
  .account-info{
    visibility: hidden;
  }

} */
@media(max-width:1600px){
  .search-bar-w{
    width: 400px;
  }

}

@media(max-width:1072px){
  .search-bar-w{
    width: 360px;
  }
}

.img-banner{
  height:fit-content
}
.lg-search div input{
  border-radius: 9999px 0 0 9999px;
  background-color: transparent;
}
.lg-search>div{
  background:white
}

.search-icon-bg{
  border-radius: 0 9999px 9999px 0;
}

/* scrollbar customizaiton */
html{
  ::-webkit-scrollbar-track {
    background-color: transparent;
  }

  ::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #c2d7ff;
    border-radius: 9px;
    &:hover {
      background-color: #9baccc;
    }
  }
  ::-webkit-scrollbar-corner {
    background-color: transparent;
  }
}
.btn-cobalt-bg{
  background-color:#001a49 !important;
}

.disabled-btn{
  background-color: #D1D1D1 !important;
  color:rgb(255, 255, 255) !important;
}

.profile-form .bg-transparent--select button{
  background-color: transparent;
  border-color: #061a4a;
}
.profile-form .profile-input--container{
  border-color: #061a4a;
}

.container-orderNumber input,.container-orderNumber textarea{
  width: 100%;
} 

nav.rdt_Pagination{
  border-radius: 1rem;
  --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
nav.rdt_Pagination > :nth-child(2){
  svg{
    display: none !important;
  }
}

.rdt_TableCell input[type='checkbox']:disabled{
  opacity: 0.3;
}

.zIndex-btn{
 z-index: -1;
}

.facet {
  &_header {
    margin-top: 37px;
    margin-bottom: 15px;
  }

  &_container {
    border-radius: 30px;
    min-height: 77px;
    margin-bottom: 20px;

    &-inner {
      margin-left: 30px;
      padding-top: 28px;
      padding-bottom: 14px;
      border-bottom: 2px solid rgba(216, 216, 216, 0.30);

      .subtitle {
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
        margin-left: 14px;
      }

      .inner-checkbox {
        border-color: #00205B;
        border-width: 2px;
      }
    }

    &-panel {
      width: 80%;
      margin: 0 auto;
      margin-top: 22px;
      padding-bottom: 24px;

      .input-panel {
        min-width: 103px;
        min-height: 33px;
        height: 43px;
        border-radius: 30px;
        border: 1px solid #00205B;
      }

      button {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 6px;
        min-height: 33px;
        height: 43px;
        border-radius: 30px;

        svg {
          position: relative;
          bottom: 1px;
        }

        span {
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: 20px;
        }
      }
    }

    &-inventory-panel {
      margin: 0 auto;
      margin-top: 14px;
      margin-bottom: 16px;

      p {
        font-size: 14px;
        font-style: normal;
        font-weight: 450;
        line-height: 30px;
      }
    }
  }

  &_link {
    color: #1F6DFF;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
  }
}
.quote{
  display: block;
  max-width: 20cm;
  min-width: 19cm;
  overflow: auto;
  font-size: 12px;
  margin: 2cm;
  clip: rect(0, 0, 0, 0);
  position: fixed;
  top: 0;
}

.quote .quote-header{
  display: flex;
  flex-wrap: nowrap;
}
.quote .quote-header div{
  width: 50%;
  box-sizing: border-box;
}
.quote .quote-header .q-header-logo img{
  max-width: 280px;
}

.quote .quote-header .q-header-folio h2{
  height: 112px;
  align-content: center;
  text-align: center;
  margin-top: 30px;
  font-size: 22px;
}
.quote .quote-header .q-header-folio p{
  text-align: center;
  margin-top: 15px;
}

.quote .body-lines{
  margin-top: 20px;
}

.quote table{
  min-width: 19cm;
}

.quote table tbody tr td:nth-child(2){
  max-width: 5cm;
}

.quote table tbody tr td:nth-child(3){
  max-width: 1.5cm;
}

@media print {
  .quote {
    grid-template-columns: repeat(1, 1fr);
    grid-auto-rows: 20cm;
    page-break-inside: avoid;
  }
}

.obstd{
  text-align: center;
}

.category {
  &-sub {
    &-box {
      top: 824px;
    }
  }
}

.rdt_TableHeadRow .rdt_TableCol{
  color: #99a6bd;
  font-weight: 700;
  font-size: 14;
  text-transform: uppercase;
}
.w-full-content{
  width: 100% !important;
}


.track-order-input div > div{
  border-color: #001a49;

}
#categories-purchases-chart .apexcharts-legend{
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}
#top-bottom-sku-chart .apexcharts-legend, #purchases-mix-chart-full .apexcharts-legend {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
}

#categories-purchases-chart .apexcharts-legend .apexcharts-legend-series, #top-bottom-sku-chart .apexcharts-legend .apexcharts-legend-series{
  text-align: left;
}
#purchases-mix-chart .apexcharts-legend {
  max-width: 300px;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
}
#purchases-mix-chart .apexcharts-legend .apexcharts-legend-marker {
  width: 12px !important;
}

#purchases-mix-chart .apexcharts-legend .apexcharts-legend-text {
  max-width: 280px !important;
}
.apex-bar { clip-path: inset(0% 0% -4px 0% round 4px); }

.qty-disabled div > div{
 border-color: gray;
}

.qty-disabled div > div > button{
  color: gray !important;
}

.qty-disabled div > div > form  input{
  color: gray !important;
}
.news-cert-blog .cursor-pointer.whitespace-pre{
  padding: 0 16px;
  width: 11rem;
  height: 57px;
  align-content: center;
}

.news-cert-blog .descnews{
  display: -webkit-box;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.5; /* Ajusta la altura de la línea según sea necesario */
  max-height: 3em; /* 2 líneas * 1.5em (line-height) */
}

#payment-reference{
  clip: rect(0, 0, 0, 0);
  position: fixed;
  top: 0;
  /* background-color: #fafafa; */
}

#payment-reference table td{
  /* text-indent: 1cm; */
  padding-left: 1cm;
  height: 1cm;
  border: 1px solid rgb(184 184 184);
  line-height: 1.5;
  padding-bottom: 12px;
}

#payment-reference table tr td:nth-of-type(2n-1){
  background-color: #001a49;
  color: #fff;
  border: 2px solid #001a49;
}

#payment-reference table:nth-child(1) tr td:nth-of-type(2n-1){
  background-color: #0b5394;
  border: 2px solid #0b5394;
}

@media(max-width: 640px) {
  .product-search input {
    padding: 12px 38px 12px 16px !important;
  }

  .product-search button {
    right: -12px;
  }

  .mobile-flex-column {
    display: flex !important;
    flex-direction: column;
  }
}

@media screen and (min-width: 1830px) {
  .custom {
    &-blog {
      &-background {
        background-color: rgba(251, 251, 251, 1);
        border-radius: 44px;
        margin: 0 60px 20px 60px !important;
        padding: 47px 45px !important; 
      }
  
      &-title {
        font-family: "ClanOT-Medium";
        font-size: 28px !important;
        font-style: normal;
        font-weight: 700;
        line-height: 28px !important; 
      }
  
      &-tile {
        min-width: 410px;
        border-radius: 30px;
        min-height: 429px;
        box-shadow: rgba(17, 12, 46, 0.15) 0px 3px 11px 0px;
        background-color: #ffffff;
  
        .img-group {
          border-top-left-radius: 30px;
          border-top-right-radius: 30px;
          min-height: 244px;
  
          img {
            object-fit: fill;
          }
        }
  
        h5 {
          font-family: "ClanOT-Medium";
          font-size: 24px;
          font-style: normal;
          font-weight: 700;
          line-height: 22px; 
          min-height: 88px;
          padding: 32px 40px 12px 34px;
        }
  
        a {
          display: block;
          padding: 12px 40px 27px 34px;
          font-family: "ClanOT-NarrNews";
          font-size: 14px;
          font-style: normal;
          font-weight: 450;
          line-height: 16px;
        }
  
        &__certifications{
          padding-left: 2rem;
          .img-group {
            img {
              object-fit: contain !important;
            }
          }
        }
      }
  
      &-page {
        display: flex;
        width: fit-content;
  
        .img-group {
          width: 815px;
          height: 458px;
          min-width: 815px;
          min-height: 458px;
          max-height: 458px;
          border-radius: 30px;
          margin-right: 43px;
          padding: 0;
  
          img {
            object-fit: fill;
            max-height: 458px;
          }
        }
  
        .desc-group {
          width: 100%;
          background-color: #ffffff;
          max-height: 832px;
          overflow: auto;
          padding: 43px 32px 43px 43px;
          border-radius: 12px;
          box-shadow: rgba(17, 12, 46, 0.15) 0px 3px 11px 0px;
  
          h5 {
            padding: 0;
            margin-bottom: 35px;
            font-family: "ClanOT-Medium";
            font-size: 24px;
            font-style: normal;
            font-weight: 700;
            line-height: 22px;
          }
  
          .blog-description {
            display: flex;
            flex-direction: column;
            gap: 30px;
            padding: 0;
  
            p {
              font-family: "ClanOT-NarrNews";
              font-size: 18px;
              font-style: normal;
              font-weight: 450;
              line-height: 22px;
            }
          }
        }
      }
    }
  }
}
@media screen and (min-width: 1024px) {
  .custom {
    &-blog {
      &-background {
        background-color: rgba(251, 251, 251, 1);
        border-radius: 44px;
        margin: 1rem !important;
        padding: 1.5rem!important; 
      }
  
      &-title {
        font-family: "ClanOT-Medium";
        font-size: 18px !important;
        font-style: normal;
        font-weight: 700; 
      }
  
      &-tile {
        border-radius: 30px;
        box-shadow: rgba(17, 12, 46, 0.15) 0px 3px 11px 0px;
        background-color: #ffffff;
  
        .img-group {
          border-top-left-radius: 30px;
          border-top-right-radius: 30px;
  
          img {
            object-fit: fill;
          }
        }
  
        h5 {
          font-family: "ClanOT-Medium";
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          padding: 1rem 1rem 0 1rem;
        }
  
        a {
          display: block;
          padding: 1rem;
          font-family: "ClanOT-NarrNews";
          font-size: 14px;
          font-style: normal;
          font-weight: 450;
          line-height: 1.5;
        }
  
        &__certifications{
          padding-left: 2rem;
          .img-group {
            img {
              object-fit: contain !important;
            }
          }
        }
      }
  
      &-page {
        display: flex;
        width: fit-content;
  
        .img-group {
          width: 815px;
          height: 458px;
          border-radius: 30px;
          padding: 0;
  
          img {
            object-fit: fill;
            max-height: 458px;
          }
        }
  
        .desc-group {
          background-color: #ffffff;
          max-height: 832px;
          overflow: auto;
          padding: 43px 32px 43px 43px;
          border-radius: 12px;
          box-shadow: rgba(17, 12, 46, 0.15) 0px 3px 11px 0px;
  
          h5 {
            padding: 0;
            margin-bottom: 35px;
            font-family: "ClanOT-Medium";
            font-size: 24px;
            font-style: normal;
            font-weight: 700;
            line-height: 22px;
          }
  
          .blog-description {
            display: flex;
            flex-direction: column;
            gap: 30px;
            padding: 0;
  
            p {
              font-family: "ClanOT-NarrNews";
              font-size: 18px;
              font-style: normal;
              font-weight: 450;
              line-height: 22px;
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width:1023px) {
  .custom {
    &-blog {
      &-background {
        background-color: rgba(251, 251, 251, 1);
        border-radius: 44px;
        margin: 0 60px 20px 60px !important;
        padding: 47px 45px !important; 
      }
  
      &-title {
        font-family: "ClanOT-Medium";
        font-size: 28px !important;
        font-style: normal;
        font-weight: 700;
        line-height: 28px !important; 
      }
  
      &-tile {
        min-width: 410px;
        border-radius: 30px;
        min-height: 429px;
        box-shadow: rgba(17, 12, 46, 0.15) 0px 3px 11px 0px;
        background-color: #ffffff;
  
        .img-group {
          border-top-left-radius: 30px;
          border-top-right-radius: 30px;
          min-height: 244px;
  
          img {
            object-fit: fill;
          }
        }
  
        h5 {
          font-family: "ClanOT-Medium";
          font-size: 24px;
          font-style: normal;
          font-weight: 700;
          line-height: 22px; 
          min-height: 88px;
          padding: 32px 40px 12px 34px;
        }
  
        a {
          display: block;
          padding: 12px 40px 27px 34px;
          font-family: "ClanOT-NarrNews";
          font-size: 14px;
          font-style: normal;
          font-weight: 450;
          line-height: 16px;
        }
  
        &__certifications{
          padding-left: 2rem;
          .img-group {
            img {
              object-fit: contain !important;
            }
          }
        }
      }
  
      &-page {
        display: flex;
        width: fit-content;
  
        .img-group {
          width: 815px;
          height: 458px;
          min-width: 815px;
          min-height: 458px;
          max-height: 458px;
          border-radius: 30px;
          margin-right: 43px;
          padding: 0;
  
          img {
            object-fit: fill;
            max-height: 458px;
          }
        }
  
        .desc-group {
          width: 100%;
          background-color: #ffffff;
          max-height: 832px;
          overflow: auto;
          padding: 43px 32px 43px 43px;
          border-radius: 12px;
          box-shadow: rgba(17, 12, 46, 0.15) 0px 3px 11px 0px;
  
          h5 {
            padding: 0;
            margin-bottom: 35px;
            font-family: "ClanOT-Medium";
            font-size: 24px;
            font-style: normal;
            font-weight: 700;
            line-height: 22px;
          }
  
          .blog-description {
            display: flex;
            flex-direction: column;
            gap: 30px;
            padding: 0;
  
            p {
              font-family: "ClanOT-NarrNews";
              font-size: 18px;
              font-style: normal;
              font-weight: 450;
              line-height: 22px;
            }
          }
        }
      }
    }
  }
}

.radioStyle-unchecked:hover {
background-image: none !important;
}
.radioStyle-checked.styles_checkbox__rLIBS:checked {
  border: 4px solid rgb(201, 201, 201) !important;
  background-image: none !important;
  box-shadow: none !important;
}

/* apex charts toolbar buttons (hide svg and csv) */
.apexcharts-menu .apexcharts-menu-item.exportSVG, .apexcharts-menu .apexcharts-menu-item.exportCSV {
  display: none;
}

.pdf-width__img {
  img{
    max-width: 180px;
  }
}

@media (min-width: 1280px) and (max-width: 1700px) {
  a div.height-img-plp {
    height: 150px ; 
  }
}
.text-wrap-break{
  word-wrap: break-word;
}

div.input-padd input{
 padding-left: 6px;
 padding-right: 6px;
}

@media (min-width: 1024px) and (max-width: 1440px) { /*1280*/
  .break-word-prop{
    word-wrap: break-word;
  }
.break-word-prop p{
  word-wrap: break-word;
  white-space: normal;
}
.break-quantity-prop div>div{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column-reverse;
}
.break-password-prop{
  flex-wrap: wrap !important;
  padding-right: .5rem;
}

div.break-confirmpass-prop{
    width: 60%;
}

.break-summary-prop .break-costs-prop{
 flex-wrap:wrap
}
.break-summary-prop .break-costs-prop p{
width: 100%;
}

div.break-summary-prop{
  padding-right: 1.5rem;
  padding-left: 1.5rem;
}

div.break-summary-prop .totalAmount-prop{
  flex-wrap: wrap;
  padding-right: 1.5rem;
  padding-left: 1.5rem;
}

div.break-summary-prop .total-order-prop{
  flex-wrap: wrap;
}

div.sku-prop{
  display: flex;
  flex:content;
}

}

@media (max-width: 768px){
  button.pdp-sm-btn{
    padding-left: 0.5rem ;
    padding-right: 0.5rem ;
  }
}


.addToCart-notification {
  position: fixed;
  top: -100px;
  right: 20px;
  background-color: #4caf50;
  color: white;
  padding: 15px 20px;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  opacity: 0;
  transition: top 0.5s ease, opacity 0.5s ease;
  z-index: 1000;
}

.addToCart-notification.show {
  top: 20px;
  opacity: 1;
}

.addToCart-notification .content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.addToCart-notification .closeBtn {
  background: none;
  border: none;
  color: white;
  font-size: 16px;
  cursor: pointer;
}

@keyframes slideInNotify {
  from {
    opacity: 0;
    transform: translateY(-20px); 
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.notification-animate-slide-in {
  animation: slideInNotify 0.5s forwards; 
}

@media (max-width: 768px) {
  .addToCart-notification-sm{
    width: 100%;
    left: 0;
    right: 0; 
    margin-left: auto;
    margin-right: auto; 
    margin-top:5rem
  
  }
}

@media (min-width: 1024px) and (max-width: 1440px){
  div.w-2-3_view{
      width: 66.666667% !important;
  }
}

@media(max-width:768px){
  div.mobile-view-bo_msg{
    display: none;
  }
}